interface UserRole {
	/** The codename used in Kontent.ai */
	codename: string
	/** The display name */
	name: string
	/** Roles that this option maps to in CDC */
	cdcRoles: string[]
}

export const USER_ROLES: UserRole[] = [
	{
		codename: 'veterinarian',
		name: 'Veterinarian',
		cdcRoles: [
			'Animal Welfare - Vet',
			'Medical / Clinic Director',
			'Tierarzt/ Tierärztin',
			'Veterinarian',
			'Veterinary Assistant',
			'Veterinarian, Practice Owner',
			'Veterinary Practice - Vet',
			'Veterinary Specialist',
			'Veterinary Surgeon',
		],
	},
	{
		codename: 'pharmacist',
		name: 'Pharmacist',
		cdcRoles: ['Pharmacist', 'Pharmacy - Staff'],
	},
	{
		codename: 'admin_staff',
		name: 'Admin / Staff',
		cdcRoles: [
			'Animal Welfare - Other',
			'Animal Welfare - Retail Staff',
			'Animal Welfare - Volunteer',
			'Business Administrator / Receptionist',
			'Business Owner / Partner',
			'Corporate Employee Non DVM',
			'Office / Practice Manager',
			'Practice Owner, Non DVM',
			'Receptionist / Client Service',
			'Veterinary Practice - Other',
		],
	},
	{
		codename: 'animal_health_specialist_retailer',
		name: 'Animal Health Specialist / Retailer',
		cdcRoles: ['OTC Retailer', 'SQP', 'OTC Retailer'],
	},
	{
		codename: 'beef_producer',
		name: 'Beef Producer',
		cdcRoles: ['Beef Producer', 'Beef / Feedlot Extension'],
	},
	{
		codename: 'dairy_producer',
		name: 'Dairy Producer',
		cdcRoles: ['Dairy Producer'],
	},
	{
		codename: 'pig_producer',
		name: 'Pig Producer',
		cdcRoles: ['Pig Producer'],
	},
	{
		codename: 'poultry_producer',
		name: 'Poultry Producer',
		cdcRoles: ['Poultry Producer '],
	},
	{
		codename: 'student',
		name: 'Student',
		cdcRoles: [
			'Academic',
			'Educational Institution - Highschool Student',
			'Educational Institution - TAFE Student',
			'Educational Institution - University Student',
			'Student RAMA',
			'Veterinary Student',
		],
	},
	{
		codename: 'veterinary_technician_nurse',
		name: 'Veterinary Technician / Nurse',
		cdcRoles: [
			'Animal Welfare - Vet Nurse',
			'Veterinary Nurse',
			'Veterinary Practice - Vet Nurse',
			'Veterinary Technician',
		],
	},
	{
		codename: 'wholesaler_distributor',
		name: 'Wholesaler / Distributor',
		cdcRoles: [
			'Distributor',
			'Distributor Rep, Field Based',
			'Distributor Rep, Inside',
			'Reseller Store - Staff',
			'Wholesaler',
		],
	},
	{
		codename: 'nutritionist',
		name: 'Nutritionist',
		cdcRoles: [
			'Nutrition / Feed Sales',
			'Consulting Nutritionist',
			'Nutritional Professional',
			'Nutritionist',
		],
	},
]

export const USER_ROLES_MODEL_AS_CODE: {codename: string; name: string}[] =
	USER_ROLES.map(({codename, name}) => ({codename, name}))
