/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @ts-check
import {clientEnv, clientSchema} from './schema.mjs'

const parsedClientEnv = clientSchema.safeParse(clientEnv)

/** @type {(errors: import('zod').ZodFormattedError<Map<string, string>, string>) => (string | null)[]} */
export const formatErrors = (errors) =>
	Object.entries(errors)
		.map(([name, value]) => {
			// eslint-disable-next-line no-underscore-dangle
			if (value && '_errors' in value) {
				return `${name}: ${value._errors.join(', ')}\n`
			}

			return null
		})
		.filter(Boolean)

if (!parsedClientEnv.success) {
	const message = `❌ Invalid environment variables:\n ${JSON.stringify(
		formatErrors(parsedClientEnv.error.format())
	)}`

	// eslint-disable-next-line no-console -- We absolutely want to console log here so we can fix the issue
	console.error(message)
	throw new Error(message)
}

for (const key of Object.keys(parsedClientEnv.data)) {
	if (!key.startsWith('NEXT_PUBLIC_')) {
		// eslint-disable-next-line no-console -- We absolutely want to console log here so we can fix the issue
		console.warn('❌ Invalid public environment variable name:', key)

		throw new Error('Invalid public environment variable name')
	}
}

export const env = parsedClientEnv.data
