import {PropsWithChildren} from 'react'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import {PageProps} from '@/pages/[[...slug]]'
import clsx from 'clsx'

const BackToTop = dynamic(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	() =>
		import(
			'@/_new-code/products/flexible-web-toolkit/components/back-to-top-button'
		).then((mod) => mod.BackToTopButton),
	{
		ssr: false,
	}
)

const Layout = (props: PropsWithChildren<PageProps>): JSX.Element => {
	const router = useRouter()

	if (router.locale === 'global') {
		return (
			<>
				<div
					className={clsx(
						props.isInPreviewMode ? 'preview-mode' : null,
						'pt-6'
					)}
				/>
				{props.children}
				<BackToTop
					hide={
						props.page.elements.snippetPageSeoHideBackToTopButton[0]
							?.codename === 'hide'
					}
					altText={
						props.page.elements.snippetPageSeoBackToTopButtonAltText
					}
				/>
			</>
		)
	}
	return (
		<div className="flex min-h-screen flex-col">
			{props.children}
			<BackToTop
				hide={
					props.page.elements.snippetPageSeoHideBackToTopButton[0]
						?.codename === 'hide'
				}
				altText={
					props.page.elements.snippetPageSeoBackToTopButtonAltText
				}
			/>
		</div>
	)
}

export default Layout
