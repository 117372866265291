import {useEffect, useRef, useState} from 'react'
import {logMessage} from '@/services/client-logger'

const cookieValueMap = {
	'permit 1,2,3': 'ALL_COOKIES',
	'permit 1,2 functional': 'FUNCTIONAL_COOKIES',
	'permit 1,3': 'ADVERTISE_COOKIES',
	'permit 1 required': 'DEFAULT_COOKIES',
} as const
type TrustArcConsent = keyof typeof cookieValueMap
type ConsentLevel = (typeof cookieValueMap)[TrustArcConsent]
/**
 * Hook to evaluate the user's current accepted level of cookie preferences
 *
 * @returns The Consent Level that the user has currently accepted
 */
export function useTrustArcConsent(): ConsentLevel {
	const [trustArcConsent, setTrustArcConsent] =
		useState<TrustArcConsent>('permit 1 required')
	const intervalRef = useRef<NodeJS.Timer | null>(null)
	const getCookieValue = (): TrustArcConsent | undefined => {
		return document.cookie
			.split('; ')
			.find((row) => row.startsWith('cmapi_cookie_privacy='))
			?.split('=')[1] as TrustArcConsent
	}
	useEffect(() => {
		const updateConsent = (): void => {
			const cookieValue = getCookieValue()
			if (cookieValue && cookieValue !== trustArcConsent) {
				logMessage(
					`Cookie consent has been updated with value : ${cookieValueMap[cookieValue]}`
				)
				setTrustArcConsent(cookieValue)
			}
		}
		// Run initially to set the value
		updateConsent()
		// Set an interval to check for changes in the cookie every 1 second
		intervalRef.current = setInterval(updateConsent, 1000)
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		}
	}, [trustArcConsent])
	return cookieValueMap[trustArcConsent]
}
