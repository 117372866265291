import type {IContentItem} from '@kontent-ai/delivery-sdk'
import type {
	AdvancedPageTemplateContentItem,
	Block,
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {AuthenticationGateLevel} from './types'

export type GateLevel = 'None' | 'Self' | 'Authenticated' | 'Validated'

export function getAuthenticationRequirements(
	page: Tersed<AdvancedPageTemplateContentItem>,
	globalConfig: Tersed<GlobalConfigContentItem>
): GateLevel {
	const globalGateLevel =
		globalConfig.elements.authenticationGateLevel[0]?.name
	const pageGateLevel = page.elements.authenticationGateLevel[0]?.name
	const actualLevel = !pageGateLevel ? globalGateLevel : pageGateLevel

	if (actualLevel === AuthenticationGateLevel.Validated) {
		return 'Validated'
	}
	if (actualLevel === AuthenticationGateLevel.Authenticated) {
		return 'Authenticated'
	}
	if (actualLevel === AuthenticationGateLevel.Self) {
		return 'Self'
	}
	return 'None'
}

export const isBlockVisible = <TContentItem extends IContentItem>(
	authenticated: boolean,
	item: Parameters<Block<TContentItem>>['0']['block']
): boolean => {
	const hideComponentOnLogin = item.elements.hideComponentOnLoginEnabled

	if (!hideComponentOnLogin || hideComponentOnLogin[0]?.codename !== 'yes') {
		return true
	}

	return !authenticated
}
