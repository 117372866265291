import type {FC, PropsWithChildren} from 'react'
import {useState, useMemo, useEffect, createContext} from 'react'
import KontentSmartLink from '@kontent-ai/smart-link'
import {useRouter} from 'next/router'
import {env} from '@/utils/env/client.mjs'

interface SmartLinkContextValue {
	readonly smartLink?: KontentSmartLink | null
}

const defaultContextValue: SmartLinkContextValue = {
	smartLink: undefined,
}

const SmartLinkContext =
	createContext<SmartLinkContextValue>(defaultContextValue)

/**
 * The SmartLinkProvider provides:
 * 1. An initialised instance of the Kontent.ai Smart Link SDK
 * 2. Access to that instance via context
 *
 * @see https://github.com/kontent-ai/smart-link
 */
export const SmartLinkProvider: FC<PropsWithChildren> = ({children}) => {
	const [smartLink, setSmartLink] = useState<KontentSmartLink | null>(null)
	const {locale = ''} = useRouter()

	useEffect(() => {
		const instance = KontentSmartLink.initialize({
			queryParam: 'preview-mode',
			defaultDataAttributes: {
				projectId: env.NEXT_PUBLIC_KONTENT_PROJECT_ID,
				languageCodename: locale,
			},
		})

		setSmartLink(instance)

		// Cleanup on component unmount
		return () => {
			instance.destroy()
		}
	}, [locale])

	const value = useMemo(() => ({smartLink}), [smartLink])

	return (
		<SmartLinkContext.Provider value={value}>
			{children}
		</SmartLinkContext.Provider>
	)
}
