import {useEffect} from 'react'
import {useTrustArcConsent} from '@/_new-code/services/trustarc'
import {pushToDataLayer} from '@/utils/analytics'

/**
 * Enable / disable MikMak's "Do Not Track" mode, preventing / enabling user analytics tracking. By default, tracking is enabled.
 *
 * @see https://docs.mikmak.ai/docs/getting-started#2-donottrack-dnt-mode
 * @param enabled - If true, user behavior will be tracked.
 */
function setMikMakDoNotTrack(enabled: boolean): void {
	window.swnDataLayer = window.swnDataLayer || {}
	window.swnDataLayer.widget_callback = addDatalayerEventstoMikMak
	if (window.swnDataLayer.setDNT) {
		window.swnDataLayer.setDNT(enabled)
	} else {
		window.swnDataLayer.dnt = enabled
	}
}

interface Product {
	ean: string
	brand: string
	name: string
	name2: string
}

interface Event {
	product: Product
	action: string
	auto: boolean
}
function addDatalayerEventstoMikMak(e: Event): void {
	const productData = {
		product_ean: e.product.ean,
		product_brand: e.product.brand,
		product_label: e.product.name,
		product_label2: e.product.name2,
	}
	// On Initial page load of Mikmak, All the product data are undefined from dploy action as we have default selected product from Mikmak hence to access that we have to go for clk+auto=1
	if (e.action === 'clk' && e.auto) {
		pushToDataLayer({
			event: 'mm_widget_open',
			...productData,
		})
	} else if (e.action === 'close') {
		pushToDataLayer({
			event: 'mm_widget_close',
			...productData,
		})
	} else if (e.action === 'dploy' && e.product.ean) {
		pushToDataLayer({
			event: 'mm_widget_open',
			...productData,
		})
	} else if (e.action === 'clk' && !e.auto) {
		pushToDataLayer({
			event: 'mm_product_selected',
			...productData,
		})
	} else if (e.action === 'buy') {
		pushToDataLayer({
			event: 'mm_click_to_retailer',
			...productData,
		})
	}
}

/**
 * Ensure that MikMak's "Do Not Track" mode respects the user's cookie privacy settings.
 *
 * This must be run on any page using MikMak. As such, it is recommended to do so in `pages/_app.tsx`.
 *
 * @param mikMakAppId - The ID of the website, provided by MikMak to us. This is different for each locale.
 */
export function useMikMak(mikMakAppId: string | null): void {
	const cookieConsent = useTrustArcConsent()
	const isMikMakAvailable = Boolean(mikMakAppId)
	useEffect(() => {
		const dntMode =
			cookieConsent === 'FUNCTIONAL_COOKIES' ||
			cookieConsent === 'DEFAULT_COOKIES' // need to enable DNT mode for these types of cookies

		if (isMikMakAvailable) {
			setMikMakDoNotTrack(dntMode)
		}
	}, [cookieConsent, isMikMakAvailable])
}
