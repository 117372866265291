import {type ModelAsCodeMultipleChoiceOption} from '@elanco/model-as-code'

const dvmEducationAppPermissionCodename = 'dvm_education'
const dvmRebateAppPermissionCodename = 'dvm_rebate'
const farmPortalAppPermissionCodename = 'farm_portal'
const petPortalAppPermissionCodename = 'pet_portal'
const techTwoTechAppPermissionCodename = 'tech_two_tech'
const vetPortalAppPermissionCodename = 'vet_portal'

/**
 * Map the Kontent.ai codename to CDC app permission
 */
export const appPermissionMapping = new Map<string, string>([
	[dvmEducationAppPermissionCodename, 'dvmEducation'],
	[dvmRebateAppPermissionCodename, 'dvmRebate'],
	[farmPortalAppPermissionCodename, 'farmPortal'],
	[petPortalAppPermissionCodename, 'petPortal'],
	[techTwoTechAppPermissionCodename, 'tech2Tech'],
	[vetPortalAppPermissionCodename, 'vetPortal'],
])

export const appPermissionModelAsCodeOptions: ModelAsCodeMultipleChoiceOption[] =
	[
		{
			codename: dvmEducationAppPermissionCodename,
			name: 'Education',
		},
		{codename: dvmRebateAppPermissionCodename, name: 'Rebates'},
		{codename: farmPortalAppPermissionCodename, name: 'Farm Portal'},
		{codename: petPortalAppPermissionCodename, name: 'Pet Portal'},
		{codename: techTwoTechAppPermissionCodename, name: 'Tech 2 Tech'},
		{codename: vetPortalAppPermissionCodename, name: 'Vet Portal'},
	]
