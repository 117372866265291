export const AuthenticationGateLevel = {
	Anonymous: '0 - Anonymous Users',
	Self: '1 - Self-Confirmation Required',
	Authenticated: '2 - Logged In',
	Validated: '3 - Logged In with a Validated Account',
} as const

export interface AppPermission {
	name: string
	codename: string
}

export interface UserRole {
	name: string
	codename: string
}
